import { IMAGES_PATH } from ".";
import {
  ENUM_CURRENCY,
  ENUM_INTRESTED_IN_PRIORITY_LIST,
  ENUM_INVITATION_STATUS_HISTORY,
  ENUM_LOOKING_FOR,
  ENUM_MEETING_STATUS,
  ENUM_PAYMENT_STATUS,
  ENUM_PRICING_STRUCTURE,
  ENUM_PROJECT_ARCHIVE_REASON,
  ENUM_PROJECT_STATUS,
} from "../enums";

const CONST_CURRENCY_LIST = {
  [ENUM_CURRENCY.USD]: { intl: "en-US" },
};

const CONST_MEETING_STATUS_LIST = {
  [ENUM_MEETING_STATUS.DONE]: {
    label: "Done",
    bgColor: "#e8f9f3",
    fontColor: "#41C197",
    eventBgColor: "#31C197",
  },
  [ENUM_MEETING_STATUS.REJECTED]: {
    label: "Rejected",
    bgColor: "#feeaf1",
    fontColor: "#F65C8D",
    eventBgColor: "#F4516C",
  },
  [ENUM_MEETING_STATUS.PENDING]: {
    label: "Pending",
    bgColor: "#ebefff",
    fontColor: "#5D78FF",
    eventBgColor: "#5867DD",
  },
};

const CONST_INVITATION_STATUS_LIST = {
  [ENUM_INVITATION_STATUS_HISTORY.CREATED]: {
    label: "Invitation Sent",
    bgColor: "#e8f9f3",
    fontColor: "#41C197",
    eventBgColor: "#31C197",
  },
  [ENUM_INVITATION_STATUS_HISTORY.AWAITING_FOR_TIME_SLOT]: {
    label: "Waiting for client slot selection",
    bgColor: "#ebefff",
    fontColor: "#5D78FF",
    eventBgColor: "#5867DD",
  },
  [ENUM_INVITATION_STATUS_HISTORY.AWAITING_FOR_TIME_SLOT_SELECTION]: {
    label: "Waiting for ambassador slot selection",
    bgColor: "#ebefff",
    fontColor: "#5D78FF",
    eventBgColor: "#5867DD",
  },
  [ENUM_INVITATION_STATUS_HISTORY.SEND_MEETING_LINK]: {
    label: "Zoom meeting link sent",
    bgColor: "#ebefff",
    fontColor: "#5D78FF",
    eventBgColor: "#5867DD",
  },
  [ENUM_INVITATION_STATUS_HISTORY.DONE]: {
    label: "Completed",
    bgColor: "#ebefff",
    fontColor: "#5D78FF",
    eventBgColor: "#5867DD",
  },
  [ENUM_INVITATION_STATUS_HISTORY.REJECTED]: {
    label: "Rejected",
    bgColor: "#feeaf1",
    fontColor: "#F65C8D",
    eventBgColor: "#F4516C",
  },
};

const CONST_PAYMENT_STATUS_LIST = {
  [ENUM_PAYMENT_STATUS.SUCCESS]: {
    label: "Success",
    bgColor: "#e8f9f3",
    fontColor: "#41C197",
  },
  [ENUM_PAYMENT_STATUS.FAILED]: {
    label: "Failed",
    bgColor: "#feeaf1",
    fontColor: "#F65C8D",
  },
};

const CONST_PROJECT_STATUS_LIST = {
  [ENUM_PROJECT_STATUS.CREATED]: {
    label: "Created",
    bgColor: "#a7a5a5",
    step: 1,
  },
  [ENUM_PROJECT_STATUS.REQUESTED]: {
    label: "Questions sent",
    bgColor: "#a7a5a5",
    step: 1,
  },
  [ENUM_PROJECT_STATUS.DONE]: {
    label: "Meeting done",
    bgColor: "green",
    step: 6,
    displayLabel: "Done",
  },
  [ENUM_PROJECT_STATUS.REJECTED]: {
    label: "Meeting rejected",
    bgColor: "#d90706",
    step: 6,
  },
  [ENUM_PROJECT_STATUS.REJECTED_BY_CLIENT]: {
    label: "Meeting rejected",
    bgColor: "#d90706",
    step: 6,
  },
  [ENUM_PROJECT_STATUS.REJECTED_BY_AMBASSADOR]: {
    label: "Meeting rejected",
    bgColor: "#FFA07A",
    step: 6,
  },
  [ENUM_PROJECT_STATUS.CANCELED_BY_CLIENT]: {
    label: "Cancelled",
    bgColor: "#d90706",
    step: 6,
  },
  [ENUM_PROJECT_STATUS.CANCELED_BY_AMBASSADOR]: {
    label: "Cancelled",
    bgColor: "#d90706",
    step: 6,
  },
  [ENUM_PROJECT_STATUS.AWAITING_FOR_RESPONSE]: {
    label: "Awaiting for response",
    bgColor: "#a7a5a5",
    step: 1,
  },
  [ENUM_PROJECT_STATUS.RESPONDED]: {
    label: "Answers received",
    bgColor: "#a7a5a5",
    step: 2,
  },
  [ENUM_PROJECT_STATUS.ADDED_TIME_SLOTS_BY_CLIENT]: {
    label: "Times suggested",
    bgColor: "#0fb3cb",
    step: 3,
  },
  [ENUM_PROJECT_STATUS.APPROVED_BY_CLIENT]: {
    label: "Approved",
    bgColor: "#66d666c7",
    step: 3,
  },
  [ENUM_PROJECT_STATUS.AWAITING_FOR_TIME_SLOT]: {
    label: "Awaiting for vendor time slot",
    bgColor: "#a7a5a5",
    step: 3,
  },
  [ENUM_PROJECT_STATUS.SELECTED_TIME_SLOT_BY_AMBASSADOR]: {
    label: "Time slot confirmed",
    bgColor: "#0fb3cb",
    step: 4,
  },
  [ENUM_PROJECT_STATUS.AWAITING_FOR_TIME_SLOT_SELECTION]: {
    label: "Awaiting for time slot selection",
    bgColor: "#a7a5a5",
    step: 4,
  },
  [ENUM_PROJECT_STATUS.SEND_MEETING_LINK]: {
    label: "Meeting link sent",
    bgColor: "#2F80ED",
    step: 5,
    displayLabel: "Scheduled",
  },
  [ENUM_PROJECT_STATUS.UPDATE_INVITATION]: {
    label: "Meeting rescheduled",
    bgColor: "#ed8b2f",
  },
  [ENUM_PROJECT_STATUS.DONE_BY_CLIENT]: {
    label: "Mark as done",
    bgColor: "green",
  },
  [ENUM_PROJECT_STATUS.DONE_BY_AMBASSADOR]: {
    label: "Mark as done",
    bgColor: "green",
  },
  [ENUM_PROJECT_STATUS.UPDATE_ATTENDEES_LIST_BY_AMBASSADOR]: {
    label: "Members added by Ambassador",
    bgColor: "#a83277",
  },
  [ENUM_PROJECT_STATUS.SUGGESTED_TIME_SLOT_BY_AMBASSADOR]: {
    label: "Suggested time slot by ambassador",
    bgColor: "#0fb3cb",
  },
  [ENUM_PROJECT_STATUS.UPDATE_ATTENDEES_LIST_BY_CLIENT]: {
    label: "Members added by Client",
    bgColor: "#a83277",
  },
  [ENUM_PROJECT_STATUS.ARCHIVE]: {
    label: "Archived",
    bgColor: "#E35335",
    displayLabel: "Archived",
  },
};

const CONST_PROJECT_ARCHIVE_REASON = {
  [ENUM_PROJECT_ARCHIVE_REASON.CANCELLED]: "Cancelled",
  [ENUM_PROJECT_ARCHIVE_REASON.RE_SCHEDULE]: "Re-schedule",
};

const CONST_LOOKING_FOR_LIST = [
  {
    label: "1:1 Introductory Sales Meetings",
    value: ENUM_LOOKING_FOR.ONE2ONE_INTRODUCTORY_SALES_MEETINGS,
  },
  { label: "1:1 Advisory Meetings", value: ENUM_LOOKING_FOR.ONE2ONE_ADVISORY },
  {
    label: "Advisory Board Members",
    value: ENUM_LOOKING_FOR.ADVISORY_BOARD_MEMBERS,
  },
  { label: "Investors", value: ENUM_LOOKING_FOR.INVESTORS },
];

const CONST_INTRESTED_IN_PRIORITY_LIST = [
  {
    label: "Low",
    value: ENUM_INTRESTED_IN_PRIORITY_LIST.LOW,
  },
  { label: "Medium", value: ENUM_INTRESTED_IN_PRIORITY_LIST.MEDIUM },
  {
    label: "High",
    value: ENUM_INTRESTED_IN_PRIORITY_LIST.HIGH,
  },
];

const FILE_TYPE_ICONS = {
  pdf: `${IMAGES_PATH}/pdf_icon.svg`,
  doc: `${IMAGES_PATH}/doc_icon.svg`,
  docx: `${IMAGES_PATH}/doc_icon.svg`,
  demo: `${IMAGES_PATH}/play_icon.svg`, // Assuming demoLink is a video file
  default: `${IMAGES_PATH}/document.svg`, // A default icon for unknown types
};

const FILTER_OPERATORS = {
  EQ: "eq",
  NE: "ne",
  GT: "gt",
  GTE: "gte",
  LT: "lt",
  LTE: "lte",
  CONTAINS: "contains",
  IN: "in",
  NIN: "nin",
  ALL: "all",
};

const FILTER_OPERATORS_LIST = [
  {
    label: "Equal",
    value: FILTER_OPERATORS.EQ,
    displayLabel: "equal to",
    tags: [
      "exact",
      "is",
      "equals",
      "equal",
      "is equal to",
      "equals to",
      "equal to",
    ],
  },
  {
    label: "Not Equal",
    value: FILTER_OPERATORS.NE,
    displayLabel: "not equal to",
    tags: ["not equal", "not equals", "not equal to"],
  },
  {
    label: "Greater Than",
    value: FILTER_OPERATORS.GT,
    displayLabel: "greater than",
    tags: ["greater than", "after", "more than"],
  },
  {
    label: "Greater Than or Equal",
    value: FILTER_OPERATORS.GTE,
    displayLabel: "greater than or equal to",
    tags: ["greater than or equal", "greater than equal"],
  },
  {
    label: "Less Than",
    value: FILTER_OPERATORS.LT,
    displayLabel: "less than",
    tags: ["less than", "before"],
  },
  {
    label: "Less Than or Equal",
    value: FILTER_OPERATORS.LTE,
    displayLabel: "less than or equal to",
    tags: ["less than or equal", "less than equal"],
  },
  {
    label: "Contains",
    value: FILTER_OPERATORS.CONTAINS,
    displayLabel: "contains",
    tags: ["contains", "contain"],
  },
  {
    label: "In",
    value: FILTER_OPERATORS.IN,
    displayLabel: "in",
    tags: ["in"],
  },
  {
    label: "Not In",
    value: FILTER_OPERATORS.NIN,
    displayLabel: "not in",
    tags: ["not in"],
  },
  {
    label: "All",
    value: FILTER_OPERATORS.ALL,
    displayLabel: "all",
    tags: ["all"],
  },
];

const CONST_MP_PRICING = [
  { id: "Thousands", label: "$ - Thousands" },
  { id: "Ten Thousands", label: "$$ - Ten Thousands" },
  { id: "Hundred Thousands", label: "$$$ - Hundred Thousands" },
  { id: "Millions", label: "$$$$ - Millions" },
];

const CONST_MP_PRICING_STRUCTURE = [
  ENUM_PRICING_STRUCTURE.PER_SEAT,
  ENUM_PRICING_STRUCTURE.PER_DATA,
];

const CONST_MP_CERTIFICATION_LIST = ["ISO", "FedRamp", "SOC2"];

export {
  CONST_CURRENCY_LIST,
  CONST_MEETING_STATUS_LIST,
  CONST_PAYMENT_STATUS_LIST,
  CONST_INVITATION_STATUS_LIST,
  CONST_PROJECT_STATUS_LIST,
  CONST_LOOKING_FOR_LIST,
  CONST_INTRESTED_IN_PRIORITY_LIST,
  CONST_PROJECT_ARCHIVE_REASON,
  FILE_TYPE_ICONS,
  FILTER_OPERATORS,
  FILTER_OPERATORS_LIST,
  CONST_MP_CERTIFICATION_LIST,
  CONST_MP_PRICING,
  CONST_MP_PRICING_STRUCTURE,
};
